<spb-logo title="Skapa sparkonto"></spb-logo>

@if (configService.logInState$ | async; as user) {
  <div class="menu-holder">
    <span>{{ user.name }}</span>

    <button [matMenuTriggerFor]="menu" mat-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
}

<!-- Add other fancy navigations here -->
<mat-menu #menu="matMenu">
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>
