import {Component} from '@angular/core'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public version = version.version

  constructor(
    public configService: ConfigService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // Add custom SVG icons to use later in <mat-icon>
    this.matIconRegistry.addSvgIcon(
      'signed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/signed.svg')
    )
  }
}
