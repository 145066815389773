import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import {MatButtonModule} from '@angular/material/button'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import {MatMenuModule} from '@angular/material/menu'
import {MatPaginatorIntl} from '@angular/material/paginator'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {
  BankIdComponent
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {SpbPaginatorSv, ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {AuthInterceptor} from './application/auth.interceptor'
import {ResponseInterceptor} from './application/response.interceptor'
import {HeaderComponent} from './components/header/header.component'
import {LoginComponent} from './components/login/login.component'
import {ConfigService} from './services/config.service'
import {DecoratorService} from './services/decorators/decorator.service'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ThemeModule,
    BankIdComponent
  ], providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [DecoratorService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    // Provide the interceptors
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline',
        subscriptSizing: 'dynamic'
      }
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
